import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import './index.css'
import ProjectCard from "../components/ProjectCard"
import PageHead from "../components/PageHead"
import { Container } from 'react-bootstrap'

export default function Blog() {
  const projects = [
    {
      name: 'Mask AI',
      description: 'Convolutional Neural Network for analysing how people wear masks.',
      demo: '/blog/maskai'
    }
  ]
  const listProjects = projects.map(({ name, description, demo }) => (
    <ProjectCard name={name} description={description} demo={demo} />
  ))
  return (
    <Layout >
      <Seo title="Blog" description="Blog posts by Prabhdip Gill" />
      <PageHead title='Blog' description='Here are some of the blog posts I have written' />
      <Container fluid  >

        {listProjects}
      </Container>
    </Layout>
  )
}
