import React from 'react'

export default function PageHead({title, description}) {
    return (
        <div className='jumbotron '>
        <div className='p-4 p-md-5 mb-4 text-black rounded  '>
            <h1> {title}</h1>
            <h5>{description}</h5>
        </div>
        </div>
    )
}
