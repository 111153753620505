import React from 'react'
import './ProjectCard.css'
import { Link } from "gatsby"
import PageHead from './PageHead'
export default function ProjectCard({ name, description, demo, blog }) {
    return (
        <>
            <div className='card'>
                <Link style={{ textDecoration: 'none', color: 'black' }} to={`${demo}`}>
                    <h6>{name}</h6>
                    <p>{description}   </p>
                </Link>
            </div>
        </>

    )
}
